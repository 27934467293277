/* Body layout styling */
.layout {
    width: 100%;
    height: 100%;
    display: grid;
    background-color: #F6F7FB !important;
    grid:
        "sidebar body" 1fr / auto 1fr;
    gap: 0px;
}

.sidebar {
    grid-area: sidebar;
}

.btn {
    box-shadow: 0px 2px 6px 0px rgb(114, 124, 245);
}

.btn-green-shadow {
    box-shadow: 0px 2px 6px 0px #a1f1da;
}

.body {
    grid-area: body;
    overflow-x: auto !important;
}

main {
    width: auto !;
    height: 100vh !important;
    /* background-color: #F6F7FB; */
    /* background-color: #3898EC; */
    overflow-y: auto;
    overflow-x: auto !important;
}

.single-card {
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

/* Navbar search  styling  */
.input-group {
    display: flex;
    align-items: center;
}

.input {
    min-height: 40px;
    min-width: 200px;
    padding: 0 1rem;
    color: black !important;
    background-color: #EEF2F7;
    border: none;
}

.message-input {
    min-height: 50px;
    max-width: 80%;
    padding: 0 1rem;
    color: black;
    font-size: 15px;
    border-radius: 2px;
    background-color: #FFFFFF;
}

.button--submit {
    min-height: 40px;
    padding: .5em 1em;
    border: none;
    background-color: #727CF4;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
}

.button--submit:hover {
    background-color: #6973E3;
}

.green-btn {
    min-height: 43px;
    padding: .5em 1em;
    border: none;
    background-color: #0ABF8C;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;
}

.green-btn:hover {
    background-color: #14a57c;
}

.input:focus,
.input:focus-visible {
    border-color: #3898EC;
    outline: none;
}

/* HR STYLIGN  */
hr.style-two {
    border: 0;
    height: 0.5px;
    background-color: #bab9b9;
}

/* Date Styling  */
*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

input[type="date"] {
    background-color: #EEF2F7;
    padding: 15px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-family: "Roboto Mono", monospace;
    color: #000000;
    /* font-size: 18px; */
    border: none;
    outline: none;
    border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}


/** LOGIN CARD STYLING  */

.blur-card {
    /* box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

/** Media query */

@media only screen and (max-width: 640px) {
    .hero-section {
        display: block !important;
    }
}